import React, { memo } from "react";
import "./PageFooter.less";
import footerLogo from "../../assets/img/Régua_Turma_Autofinanciada_Colorida_Fundo_Branco.jpg";
import { version } from "../../utils/constants";
 
interface Props {}
const PageFooter: React.FC<Props> = () => {
  return (
   <div className="footer-container">
      <div className="banner-footer">
        <img src={footerLogo} alt="Organizadores" />
      </div>
      <span>{version}</span>
    </div>
  );
};
 
export default memo(PageFooter);