import React, { useCallback } from "react";
import "./InitialPage.less";

import PageContainer from "layouts/PageContainer";
import carousel2 from "../../assets/img/carroussel_2.png";
import carousel3 from "../../assets/img/carroussel_3.png";
import { ArrowRightOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { Carousel, Col, Divider, Row } from "antd";
import { useHistory } from "react-router-dom";
import PeopleCard from "components/PeopleCard";
import DepoimentosCard from "components/DepoimentosCard";
import fundamentos from "../../assets/initial_page_icons/fundamentos.png";
import registro_saude from "../../assets/initial_page_icons/registro_saude.png";
import gestao_economia from "../../assets/initial_page_icons/gestao_economia.png";
import inovacao_tendencia from "../../assets/initial_page_icons/inovacao_tendencia.png";
import padroes_sevicos_iteroperabilidade from "../../assets/initial_page_icons/padroes_sevicos_iteroperabilidade.png";
import tcc from "../../assets/initial_page_icons/tcc.png";
import { navigateExternal } from "utils/navigateExternal";
import Banner_ebooks from "../../assets/img/Banner_ebooks.png";
import Banner_edital from "../../assets/img/Banner_edital.png";
import Banner_conheca from "../../assets/img/Banner_Conheça o Nosso Curso.png";
import Banner_live from "../../assets/img/Banner_SiteEspecialização_CarrosselNotícias_1920x624px_Turma Autofinanciada_Live.png"
import Banners_Inscricoes_Prorrogadas from "../../assets/img/Banners_Inscricoes_Prorrogadas.png"

interface Props {}
const InitialPage: React.FC<Props> = () => {
  const history = useHistory();
  const navigate = useCallback(
    (url_destino: string) => {
      history.push(`/p/${url_destino}`);
    },
    [history]
  );

  const SampleNextArrow = (props: any) => {
    const { onClick } = props;
    return (
      <div className="arrow-custom arrow-custom-right">
        <ArrowRightOutlined onClick={onClick} />
      </div>
    );
  };

  const SamplePrevArrow = (props: any) => {
    const { onClick } = props;
    return (
      <div className="arrow-custom arrow-custom-left">
        <ArrowLeftOutlined onClick={onClick} />
      </div>
    );
  };

  const settings = {
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <PageContainer>
      <div className="main-intro-page">
        <section className="main-slider-intro">
          <Carousel speed={300} autoplay arrows {...settings}>



            <div>
              <img
                onClick={() => navigate("conhecacurso")}
                className="banner-carousel"
                src={Banner_conheca}
                alt="conheça nosso curso"
              />
            </div>
            <div>
              <img
                onClick={() => navigate("professores_conteudistas")}
                className="banner-carousel"
                src={carousel2}
                alt="Professores Conteúdistas"
              />
            </div> 
                       
            <div>
              <img
                onClick={() => navigate("estrutura")}
                className="banner-carousel"
                src={carousel3}
                alt="Conferir estrutura do curso"
              />
            </div>

            <div>
              <img
                onClick={() => navigate("ebooks")}
                className="banner-carousel"
                src={Banner_ebooks}
                alt="ebooks"
              />
            </div>

          </Carousel>
        </section>
        <Divider />
        <section style={{ marginTop: 32 }}>
          <h1>Áreas Temáticas</h1>

          <Row gutter={16} className="row-card-class">
            <Col md={8} xs={24} sm={12}>
              <PeopleCard
                gradientColor1="0b3279"
                gradientColor2="007757"
                imageSrc={fundamentos}
                title="Fundamentos"
              />
            </Col>

            <Col md={8} xs={24} sm={12}>
              <PeopleCard
                gradientColor1="0b3279"
                gradientColor2="007757"
                imageSrc={registro_saude}
                title="Registro de Saúde"
              />
            </Col>
            <Col md={8} xs={24} sm={12}>
              <PeopleCard
                gradientColor1="0b3279"
                gradientColor2="007757"
                imageSrc={gestao_economia}
                title="Gestão e Epidemiologia"
              />
            </Col>
          </Row>

          <Row gutter={16} className="row-card-class">
            <Col md={8} xs={24} sm={12}>
              <PeopleCard
                gradientColor1="0b3279"
                gradientColor2="007757"
                imageSrc={padroes_sevicos_iteroperabilidade}
                title="Padrões de serviço e Interoperabilidade"
              />
            </Col>

            <Col md={8} xs={24} sm={12}>
              <PeopleCard
                gradientColor1="0b3279"
                gradientColor2="007757"
                imageSrc={inovacao_tendencia}
                title="Inovações e Tendências"
              />
            </Col>
            <Col md={8} xs={24} sm={12}>
              <PeopleCard
                gradientColor1="0b3279"
                gradientColor2="007757"
                imageSrc={tcc}
                title="Trabalho de Conclusão de Curso"
              />
            </Col>
          </Row>
        </section>
        

      </div>
      
    </PageContainer>
  );
};

export default InitialPage;
